<template>
    <div class="index">
        <z-header :curActive="6"></z-header>
        <div class="typeArea">
            <div class="us-title">
                <span>尊敬的访客您好：</span>
                <span>欢迎您来到云南省勐海县数字茶业门户网站，您可以通过以下方式联系到我们。</span>
            </div>
            <div class="us-content">
                <div>
                    <img src="../../imgs/us/phone.png">
                    <span>联系电话：0691-3057800</span>
                </div>
                <div>
                    <img src="../../imgs/us/mail.png">
                    <span>联系邮箱：mhxgyyqyt@163.com</span>
                </div>
                <div>
                    <img src="../../imgs/us/map.png">
                    <span>联系地址：云南省西双版纳傣族自治州勐海县勐海镇产业园区</span>
                </div>
            </div>
        </div>
        <div class="typeArea us-map">
            <div class="map-search-box">
                <el-autocomplete
                    v-model="mapLocation.address"
                    :fetch-suggestions="querySearch"
                    placeholder="勐海县"
                    :trigger-on-focus="false"
                    @select="handleSelect"
                    class="map-search"
                />
                <!-- <el-button @click="handleSelect" type="primary" icon="el-icon-search"></el-button> -->
            </div>
            <baidu-map class="map" :center="mapCenter" :zoom="mapZoom" :scroll-wheel-zoom="true"  @ready="handlerBMap"></baidu-map>
        </div>
	</div>
</template>
<script>
import zHeader from "../../components/header.vue"
import zTitle from "../../components/z-title.vue"
export default {
    components:{zHeader,zTitle},
    data(){
        return{
            mapCenter: {lng: 100.460595, lat: 21.962629},
            mapZoom: 17,
            mapLocation: {
                address: undefined,
                coordinate: undefined
            }
        }
    },
    methods:{
        handlerBMap({ BMap, map }) {
            this.BMap = BMap
            this.map = map
            if (this.mapLocation.coordinate && this.mapLocation.coordinate.lng) {
                this.mapCenter.lng = this.mapLocation.coordinate.lng
                this.mapCenter.lat = this.mapLocation.coordinate.lat
                this.mapZoom = 17
                map.addOverlay(new this.BMap.Marker(this.mapLocation.coordinate))
            } else {
                this.mapCenter.lng = 100.460595
                this.mapCenter.lat = 21.962629
                this.mapZoom = 17
            }
        },
        querySearch(queryString, cb) {
            var that = this
            var myGeo = new this.BMap.Geocoder()
            myGeo.getPoint(queryString, function(point) {
                if (point) {
                that.mapLocation.coordinate = point
                that.makerCenter(point)
                } else {
                that.mapLocation.coordinate = null
                }
            }, this.locationCity)
            var options = {
                onSearchComplete: function(results) {
                if (local.getStatus() === 0) {
                    // 判断状态是否正确
                    var s = []
                    for (var i = 0; i < results.getCurrentNumPois(); i++) {
                    var x = results.getPoi(i)
                    var item = { value: x.address + x.title, point: x.point }
                    s.push(item)
                    cb(s)
                    }
                } else {
                    cb()
                }
                }
            }
            var local = new this.BMap.LocalSearch(this.map, options)
            local.search(queryString)
        },
        handleSelect(item) {
            var { point } = item
            this.mapLocation.coordinate = point
            this.makerCenter(point)
        },
        makerCenter(point) {
            if (this.map) {
                this.map.clearOverlays()
                this.map.addOverlay(new this.BMap.Marker(point))
                this.mapCenter.lng = point.lng
                this.mapCenter.lat = point.lat
                this.mapZoom = 15
            }
        }
    }
}
</script>
<style scoped>
    .index{
        padding-bottom:150px;
    }
    .index .typeArea{
        display: flex;
        flex-direction: column;
    }
    .us-title{
        width: 100%;
        border-bottom: 1px solid #ccc;
        display: flex;
        flex-direction: column;
        font-size: 16px;
        line-height: 30px;
        color: #333333;
        padding: 80px 0 40px;
        box-sizing: border-box;
    }
    .us-content{
        width: 100%;
        padding: 40px 0 50px;
        box-sizing: border-box;

        font-size: 15px;
        color: #333333;
        line-height: 35px;
    }
    .us-content>div{
        display: flex;
        align-items: center;
        margin-bottom: 15px;
    }
    .us-content>div>img{
        width: 35px;
        height: 35px;
        margin-right: 30px;
    }
    .map{
        width: 100%;
        height: 600px;
        border-radius: 4px;
	    border: solid 1px #dddddd;
    }
    .us-map{
        position: relative;
    }
    /* .map-search{
        width: 247px;
        border-radius: 4px 0 0 4px;
    } */
    .el-input__inner{
        width: 247px;
    }
    .map-search-box{
        display: flex;
        justify-content: flex-start;
        position: absolute;
        z-index: 9;
        border-radius: 4px 0 0 4px;
        top: 13px;
        left: 11px;
    }
    .el-button{
        padding: 10px 12px;
        border-radius: 0 4px 4px 0;
    }

    /* 移动端样式 */
    @media only screen and (max-width:1199px){
        .index .typeArea{
            width:100%;
        }
        .map{
            height:100%;
        }
        .us-title{
            padding: 18px 20px;
            font-size: 16px;
            line-height: 26px;
            border: none;
            color: #867d70;
        }
        .us-content{
            padding: 0 20px;
        }
        .us-content>div>img{
            margin-right: 20px;
        }
        .us-content{
            font-size: 14px;
        }
        .us-map{
            padding: 0 20px;
            box-sizing: border-box;
            height: 230px;
            margin-top: 10px;
        }
        .map-search-box{
            top: 12px;
            left: 32px;
            z-index: 9;
        }
        .index{
            padding-bottom:50px;
        }
        .el-input__inner{
            height: 30px;
        }
    }
</style>